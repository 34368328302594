import React from "react";
import { Badge } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";

import themeColor from "../../lib/style/theme";
import NodeTranslations from "../../lib/translations/nodeTranslations";
import { MESSAGE_TYPE } from "../../lib/utils/constants";
import CloudTranslations from "../../lib/translations/cloudTranslations";

const NotificationsPageColumns = ({ formatMessage }) => {
  const columns = [
    {
      title: <FormattedMessage id="NotificationsPage.counter" defaultMessage="Counter" />,
      dataIndex: "counter",
      key: "counter",
      align: "center",
      render: (counter) => {
        return <Badge count={counter} style={{ backgroundColor: themeColor.orange, color: "#FFF" }} />;
      },
    },
    {
      title: <FormattedMessage id="NotificationsPage.lastTime" defaultMessage="Last time" />,
      dataIndex: "time",
      key: "time",
      render: (time, record) => {
        const isError = record.msgType === MESSAGE_TYPE.ERROR;

        return isError ? <StyledError>{time}</StyledError> : <StyledWarning>{time}</StyledWarning>;
      },
    },
    {
      title: <FormattedMessage id="NotificationsPage.node" defaultMessage="Node" />,
      dataIndex: "cnn",
      key: "cnn",
      render: (cnn, record) => {
        const linkUrl = `/${record.cwid}/${cnn}/channels`;

        return <Link to={linkUrl}>{cnn}</Link>;
      },
    },
    {
      title: <FormattedMessage id="NotificationsPage.channelName" defaultMessage="Channel" />,
      dataIndex: "channelName",
      key: "channelName",
      render: (channelName, record) => {
        const linkUrl = `/${record?.cwid}/${record?.cnn}/channel/${record?.channelId}`;

        if (record?.channelId <= 0) {
          return null;
        }

        return <Link to={linkUrl}>{channelName}</Link>;
      },
    },
    {
      title: <FormattedMessage id="NotificationsPage.notification" defaultMessage="Notification" />,
      dataIndex: "errorCode",
      key: "errorCode",
      render: (code, record) => {
        const isError = record.msgType === MESSAGE_TYPE.ERROR;
        const warningKey = `warning-${record?.warningCode}`;
        const missingTranslation = isError ? !(code in NodeTranslations) : !(warningKey in NodeTranslations);

        if (missingTranslation) {
          return isError ? (
            <StyledError>{`${formatMessage(CloudTranslations[0])} (${code})`}</StyledError>
          ) : (
            <StyledWarning>{`${formatMessage(CloudTranslations[0])} (${record.warningCode})`}</StyledWarning>
          );
        }

        return isError ? (
          <StyledError>{formatMessage(NodeTranslations[code])}</StyledError>
        ) : (
          <StyledWarning>{formatMessage(NodeTranslations[`warning-${record.warningCode || 0}`])}</StyledWarning>
        );
      },
    },
  ];

  return columns;
};

const StyledError = styled.span`
  color: ${themeColor.lightRed};
`;

const StyledWarning = styled.span`
  color: ${themeColor.orange};
`;

export default NotificationsPageColumns;
