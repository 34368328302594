import React, { useRef, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Col, Row, Select, Button, Radio, Divider, Card, Spin, Statistic } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import styled from "styled-components";

import store from "../../../store";

import MQTTService from "../../../services/mqtt";
import { actions as NODE_STATS_V5_ACTIONS, selectors as NODE_STATS_V5_SELECTOR } from "../../../ducks/nodeStatsV5";

import { MESSAGE_TYPE } from "../../../lib/utils/constants";
import { GRAPH_OPTIONS, GRAPH_DATASET_OPTIONS } from "../../../lib/style/theme";
import nodeTranslations from "../../../lib/translations/nodeTranslations";
import getTimeRangeData from "../../../lib/utils/getTimeRangeData";

import StatisticsGraphTitle from "./StatisticsGraphTitle";
import StatsScaleSelector from "../../../components/StatsScaleSelector";

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Countdown } = Statistic;

export default function StatisticHistoricalV5Graph({ channelId, selectorOptions, channelParams }) {
  const { formatMessage } = useIntl();
  const statRef = useRef(null);
  const { cwid } = useParams();
  const data = useSelector(NODE_STATS_V5_SELECTOR.getStatsData);

  const defaultDataRange = useSelector(NODE_STATS_V5_SELECTOR.getStatsRangeParam);
  const statScale = useSelector(NODE_STATS_V5_SELECTOR.getStatsScale);
  const [selectedStat, setSelectedStat] = useState(null);
  const [liveTimeInterval, setLiveTimeInterval] = useState(null);

  useEffect(() => {
    Chart.register(zoomPlugin);

    return () => {
      Chart.unregister(zoomPlugin);
    };
  }, []);

  const [selectedRange, setSelectedRange] = useState(getTimeRangeData(defaultDataRange));

  useEffect(() => {
    store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
  }, [cwid]);

  useEffect(() => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.GET_STAT_PARAM_NAMES,
        channelId,
        start: selectedRange?.start,
        end: selectedRange?.end,
      },
    });
  }, [selectedRange, channelId]);

  useEffect(() => {
    setSelectedStat(null);
  }, [channelId]);

  const selectorOptionParams =
    selectorOptions && selectorOptions.find((selectorOption) => selectorOption?.value === selectedStat);
  const parsedChannelParams = channelParams || selectorOptionParams?.params || {};

  const { p1: param1, p2: param2, p3: param3, s: source } = parsedChannelParams;
  const isChannelParams = Object.keys(parsedChannelParams).length > 0;

  useEffect(() => {
    if (selectedStat) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_STAT_VALUES,
          channelId,
          start: selectedRange?.start,
          end: selectedRange?.end,
          statName: selectedStat,
          param1,
          param2,
          param3,
          scale: statScale,
          source,
        },
      });
    }
  }, [param1, param2, param3, source, selectedRange, channelId, cwid, selectedStat, statScale]);

  useEffect(() => {
    const { value } = selectedRange;
    let liveInterval = null;

    if (value === 0.5 && isChannelParams && selectedStat) {
      setLiveTimeInterval(dayjs());
      liveInterval = setInterval(() => {
        const { start, end } = getTimeRangeData(value);
        setLiveTimeInterval(dayjs());

        MQTTService.sendMessage({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.GET_STAT_PARAM_NAMES,
            channelId,
            start,
            end,
          },
        });

        MQTTService.sendMessage({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.GET_STAT_VALUES,
            channelId,
            start,
            end,
            statName: selectedStat,
            param1,
            param2,
            param3,
            scale: statScale,
            source,
          },
        });
      }, 30000);
    } else {
      clearInterval(liveInterval);
    }

    return () => {
      clearInterval(liveInterval);
    };
  }, [selectedRange, param1, param2, param3, source, cwid, channelId, selectedStat, isChannelParams, statScale]);

  const handleResetZoom = () => {
    if (statRef.current) {
      statRef.current.resetZoom();
    }
  };

  const handleChangeChannelStatParam = (selectedStatName) => {
    setSelectedStat(selectedStatName);

    if (!selectedStatName) {
      store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
    }

    handleResetZoom();
  };

  const isMemUsedStat = selectedStat === "memUsed";

  const parseStatisticName = (statisticName) => {
    if (isMemUsedStat) {
      return formatMessage(nodeTranslations.memUsedMB);
    }

    const translationExist = statisticName in nodeTranslations;

    return translationExist ? formatMessage(nodeTranslations[statisticName]) : statisticName;
  };

  const convertBytesToMb = (bytes) => {
    return (+bytes / 1024 / 1024).toFixed(2);
  };

  const parsedData = {
    labels: data.map((statistic) => statistic.timestamp),
    datasets: [
      {
        label: (selectedStat && parseStatisticName(selectedStat)) || "No statistic selected",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        data: data && data.map((statistic) => (isMemUsedStat ? convertBytesToMb(statistic.value) : statistic.value)),
        ...GRAPH_DATASET_OPTIONS,
      },
    ],
  };

  const handleChangeDateRange = (event) => {
    handleResetZoom();
    store.dispatch(NODE_STATS_V5_ACTIONS.CLEAR_STATS_V5_DATA());
    store.dispatch(NODE_STATS_V5_ACTIONS.SET_DATE_RANGE_PARAMS_V5(event.target.value));

    switch (event.target.value) {
      case 1:
        setSelectedRange(getTimeRangeData(1));
        setLiveTimeInterval(null);

        return;

      case 6:
        setSelectedRange(getTimeRangeData(6));
        setLiveTimeInterval(null);

        return;

      case 24:
        setSelectedRange(getTimeRangeData(24));
        setLiveTimeInterval(null);

        return;

      case 48:
        setSelectedRange(getTimeRangeData(48));
        setLiveTimeInterval(null);

        return;

      case 72:
        setSelectedRange(getTimeRangeData(72));
        setLiveTimeInterval(null);

        return;

      default:
        setSelectedRange(getTimeRangeData(0.5));
        store.dispatch(NODE_STATS_V5_ACTIONS.SET_STAT_SCALE_V5(1));
        if (selectedStat) {
          setLiveTimeInterval(dayjs());
        }
    }
  };

  const deadline30sFuture = liveTimeInterval && liveTimeInterval.add(30, "second");

  return (
    <Card>
      <Row justify="center">
        <Col span={18}>
          <Row justify="space-between">
            <Col>
              <FormattedMessage id="StatisticHistoricalV5.statistic" defaultMessage="Statistic" />
              {": "}
              <Select
                autoFocus
                style={{ width: 220 }}
                options={selectorOptions}
                onChange={handleChangeChannelStatParam}
                value={selectedStat}
                allowClear
              />
              <StatisticsGraphTitle hideTitle />
            </Col>
            <Col>
              <StatsScaleSelector selectedRangeValue={selectedRange?.value} />
              <RadioGroup defaultValue={defaultDataRange} onChange={handleChangeDateRange} buttonStyle="solid">
                <RadioButton value={0.5}>
                  {liveTimeInterval && (
                    <StyledCountdown value={deadline30sFuture} prefix={<LoadingOutlined />} format="ss" />
                  )}
                  <FormattedMessage id="StatisticHistoricalV5.live" defaultMessage="Live" />
                </RadioButton>
                <RadioButton value={1}>
                  <FormattedMessage id="StatisticHistoricalV5.1hour" defaultMessage="1 hour" />
                </RadioButton>
                <RadioButton value={6}>
                  <FormattedMessage id="StatisticHistoricalV5.6hour" defaultMessage="6 hours" />
                </RadioButton>
                <RadioButton value={24}>
                  <FormattedMessage id="StatisticHistoricalV5.1day" defaultMessage="1 day" />
                </RadioButton>
                <RadioButton value={48}>
                  <FormattedMessage id="StatisticHistoricalV5.2days" defaultMessage="2 days" />
                </RadioButton>
                <RadioButton value={72}>
                  <FormattedMessage id="StatisticHistoricalV5.3days" defaultMessage="3 days" />
                </RadioButton>
              </RadioGroup>
            </Col>
            <Col>
              <Button type="primary" onClick={handleResetZoom}>
                <FormattedMessage id="StatisticHistoricalV5Graph.resetZoom" defaultMessage="Reset zoom" />
              </Button>
            </Col>
          </Row>
          <Spin
            tip={
              <FormattedMessage
                id="StatisticHistoricalV5Graph.pleaseSelectStatistic"
                defaultMessage="Please use statistic selector for data"
              />
            }
            spinning={!selectedStat}
            indicator={<InfoCircleOutlined />}
          >
            <Row justify="center">
              <Divider />
              <Col span={24}>
                <Line data={parsedData} options={GRAPH_OPTIONS} ref={statRef} />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
}

const StyledCountdown = styled(Countdown)`
  display: inline-block;
  margin-right: 4px;
  min-width: 28px;

  .ant-statistic-content {
    font-size: 10px;
  }
`;
