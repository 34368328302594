import React from "react";
import PropTypes from "prop-types";
import useNodeVersion from "../../../hooks/useNodeVersion";
import useSetNodeConnection from "../../../hooks/useSetNodeConnection";
import useNodeChannels from "../../../hooks/useNodeChannels";
import useRequestNodeLiveStats from "../../../hooks/useRequestNodeLiveStats";

import StatisticsLive from "./StatisticsLive";
import StatisticHistoricalV5 from "./StatisticHistoricalV5";

const StatisticsContainer = ({ channelDetailView }) => {
  const { isV5Node } = useNodeVersion();

  useSetNodeConnection();
  useNodeChannels();
  useRequestNodeLiveStats();

  return (
    <>
      {!isV5Node && <StatisticsLive channelDetailView={channelDetailView} />}
      {isV5Node && <StatisticHistoricalV5 channelDetailView={channelDetailView} />}
    </>
  );
};

StatisticsContainer.propTypes = {
  channelDetailView: PropTypes.bool,
};

StatisticsContainer.defaultProps = {
  channelDetailView: null,
};

export default StatisticsContainer;
