import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import dayjs from "dayjs";

import { LOGS_TYPE } from "../lib/utils/constants";

export const SET_LOG_LEVEL = createAction("LOGS/SET_LOG_LEVEL");
export const LOAD_MORE_LOG_DATA = createAction("LOGS/LOAD_MORE_LOG_DATA");
export const NEW_LOG_DATA = createAction("LOGS/NEW_LOG_DATA");
export const DELETE_LOGS = createAction("LOGS/DELETE_LOGS");
export const DELETE_LOGS_DATA = createAction("LOGS/DELETE_LOGS_DATA");
export const SET_LOGS_PARAMS = createAction("LOGS/SET_LOGS_PARAMS");
export const DELETE_LOGS_PARAMS = createAction("LOGS/DELETE_LOGS_PARAMS");
export const SET_LOADING_LOGS = createAction("LOGS/SET_LOADING_LOGS");

const defaultState = new Map({
  logsMetaData: null,
  data: [],
  params: [],
  loading: false,
});

const reducer = handleActions(
  {
    [SET_LOG_LEVEL]: (state, { payload }) => {
      return state.merge({ logsMetaData: payload });
    },
    [LOAD_MORE_LOG_DATA]: (state, { payload: { logs, channelId } }) => {
      const stateLogs = state.get("data");
      const oldChannelLogs = stateLogs[channelId] || [];

      const parsedLogs = logs.map((log) => ({
        ...log,
        parsedTime: (log.time && dayjs.unix(log.time).format("D MMM, HH:mm:ss")) || "-----------------",
      }));

      return state.merge({ data: { [channelId]: [...oldChannelLogs, ...parsedLogs] } });
    },
    [NEW_LOG_DATA]: (state, { payload: { channelId, description, engineStatus, errorCode, id, level, time } }) => {
      const parsedTime = dayjs(time).format("YYYY dd MMM, HH:mm:ss");

      const newLog = { channelId, description, engineStatus, errorCode, id, level, time, parsedTime };
      const stateLogs = state.getIn(["data", `${channelId}`]);
      const allLogsIsActive = state.getIn(["data", `${LOGS_TYPE.all}`]);

      if (allLogsIsActive) {
        return state.mergeIn(["data", `${LOGS_TYPE.all}`], [newLog]);
      }
      if (!stateLogs) return state;

      return state.mergeIn(["data", `${channelId}`], [newLog]);
    },
    [SET_LOGS_PARAMS]: (state, { payload: { sourceIds } }) => {
      const parsedSourceIds = sourceIds.map((sourceId) => ({
        label: sourceId?.e ? sourceId.e.charAt(0).toUpperCase() + sourceId.e.slice(1) : "",
        value: JSON.stringify(sourceId),
      }));

      return state.merge({ params: parsedSourceIds });
    },
    [DELETE_LOGS_DATA]: (state) => {
      return state.merge({ data: [] });
    },
    [DELETE_LOGS_PARAMS]: (state) => {
      return state.merge({ params: [] });
    },
    [SET_LOADING_LOGS]: (state, { payload: { loading } }) => {
      return state.merge({ loading });
    },
    [DELETE_LOGS]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getLogLevel: (state) => state.getIn(["logs", "logsMetaData", "level"]),
  getLogs: (state) => state.getIn(["logs", "data"]),
  getLogsSourceParams: (state) => state.getIn(["logs", "params"]),
  getLogsLoading: (state) => state.getIn(["logs", "loading"]),
};

export default reducer;
